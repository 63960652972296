import { useEffect } from "react";
import { checkoutSessionStatus } from "../utils/firebase";

const useWatchURL = (activateUser) => {
  const checkStripeSession = async (sessionId) => {
    try {
      const { status } = await checkoutSessionStatus(sessionId);
      if (status === "complete") {
        activateUser();
      }
      const baseUrl = window.location.origin;
      window.history.replaceState(null, "", baseUrl);
    } catch (error) {
      console.error("Error checking Stripe session status:", error);
      alert("Failed to check session status. Please try again.");
    }
  };

  useEffect(() => {
    const handleURLChange = () => {
      const url = new URL(window.location.href);
      if (url.pathname === "/stripe/return") {
        const sessionId = url.searchParams.get("session_id");
        if (sessionId) {
          checkStripeSession(sessionId);
        }
      }
    };

    // Initial check
    handleURLChange();

    // Set up the event listener for URL changes
    window.addEventListener("popstate", handleURLChange);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("popstate", handleURLChange);
    };
  }, []);
};

export default useWatchURL;
