import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slider,
  Typography,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { createCheckoutSession } from "../utils/firebase";

const testPublicKey =
  "pk_test_51IHuKSLNtDeWWdaBeVvCvhxIg5E4gQAGfXGwZfopxl33x9GjfXpUtDnQrcnviIy6yzRcGpJCdgkDRc11VKffqlqw00JeAsEHVl";
const livePublicKey =
  "pk_live_51IHuKSLNtDeWWdaB0cYagcNo21BlWqvvYnJgGYpMHSU0Y6CxsHnq8FEKDl1TBbShhSTumtmabavT2xSJdvJ9G9co00LGDiOxh4";

const stripePromise = loadStripe(livePublicKey);

const CheckoutForm = ({ open, setOpen, clientSecret }) => {
  const options = { clientSecret };

  return (
    <Dialog
      id="checkout"
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          padding: "10px 0px",
        },
      }}
    >
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Dialog>
  );
};

const PaymentDialog = ({ user, open, onClose }) => {
  const [amount, setAmount] = useState(5);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stripeCheckoutDialogOpen, setStripeCheckoutDialogOpen] =
    useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);

  const handleContinue = async () => {
    setLoading(true);
    setError(null);
    const { uid } = user;
    const { clientSecret } = await createCheckoutSession(
      uid,
      `${amount}`,
      window.location.origin
    );
    setStripeClientSecret(clientSecret);
    setStripeCheckoutDialogOpen(true);
  };

  return (
    <>
      {stripeCheckoutDialogOpen && stripeClientSecret ? (
        <CheckoutForm
          open={stripeCheckoutDialogOpen}
          setOpen={setStripeCheckoutDialogOpen}
          clientSecret={stripeClientSecret}
        />
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: "#333",
              color: "#fff",
            },
          }}
        >
          <DialogTitle style={{ color: "#fff" }}>
            You've Reached Free Limit
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" color="white">
              Subscribe to continue asking questions! You can select your price.
              Any amount is appreciated so that we can continue providing this
              service :)
            </Typography>
            <Slider
              value={amount}
              onChange={(event, newValue) => setAmount(newValue)}
              min={1}
              max={10}
              step={1}
              sx={{
                padding: "10px 0px",
                height: "25px",
                ".MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover": {
                  boxShadow: "none !important",
                },
                "& .MuiSlider-thumb": {
                  color: "#b469ff",
                  width: 40,
                  height: 40,
                  "&::after": {
                    content: `"${"$"}${amount}"`,
                    position: "absolute",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    top: "2em",
                    left: "2em",
                  },
                },
                "& .MuiSlider-track": {
                  color: "#d9b3ff",
                },
                "& .MuiSlider-rail": {
                  color: "#d9b3ff",
                },
                "& .MuiSlider-active": {
                  color: "#d9b3ff",
                },
              }}
            />
            {error && (
              <Typography color="error" variant="body2" mt={2}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              id="subscribe-button"
              onClick={handleContinue}
              variant="contained"
              sx={{ backgroundColor: "#b469ff" }}
              disabled={loading}
            >
              {loading ? "Loading..." : `Subscribe for $${amount} per month`}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PaymentDialog;
