import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";
import {
  HarmBlockThreshold,
  HarmCategory,
  getVertexAI,
  getGenerativeModel,
} from "firebase/vertexai-preview";
import { getFunctions, httpsCallable } from "firebase/functions";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAOvFNpqsBW_BieR-wDqfVUvyqgPau9Q5I",
  authDomain: "evangara.com",
  projectId: "firelock-bible-chat",
  storageBucket: "firelock-bible-chat.appspot.com",
  messagingSenderId: "329805971971",
  appId: "1:329805971971:web:b9d4d700c8e320e9e8c436",
  measurementId: "G-77JRE6SZVP",
};

// Initialize FirebaseApp
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(firebaseApp);

// Initialize Firebase services
const vertexAI = getVertexAI(firebaseApp);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);

const safetySettings = [
  {
    category: HarmCategory.HARM_CATEGORY_HARASSMENT,
    threshold: HarmBlockThreshold.BLOCK_ONLY_HIGH,
  },
  {
    category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
    threshold: HarmBlockThreshold.BLOCK_ONLY_HIGH,
  },
  {
    category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
    threshold: HarmBlockThreshold.BLOCK_ONLY_HIGH,
  },
  {
    category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
    threshold: HarmBlockThreshold.BLOCK_ONLY_HIGH,
  },
  {
    category: HarmCategory.HARM_CATEGORY_UNSPECIFIED,
    threshold: HarmBlockThreshold.BLOCK_ONLY_HIGH,
  },
];

// Initialize the generative model with a model that supports your use case
const model = getGenerativeModel(vertexAI, {
  model: "gemini-1.5-pro",
  systemInstruction: `
    Your name is Evangara! A blend of "Evangel" (gospel) and "Ara" (altar), indicating a source of the good news and divine love.
    You are to help people converse about, understand more, and dive deeper into the Christian faith.
    At all times, you are to exhibit the Fruits of the Spirit (Love, Joy, Peace, Patience, Kindness, Goodness, Faithfulness, & Self-Control).
    You are to provide comprehensive and informative answers to the user's questions, delving into the details and background of topics related to Christianity.
    Do not ask the user for more specifics unless absolutely necessary. Provide as much detail and insight as possible in your responses.
    You can fetch verses as needed to share specifics, focus on giving great insight and welcoming a dialogue. You are to be inviting and promotional about Jesus' love.
    You are not to be denomination-specific. Instead, focus on promoting the love of Jesus. Truly, a more proper term is "Jesus Followers" instead of Christians. That's really what's important about the faith.
    IMPORTANT! --> While you are meant to be loving, do not provide false or unrealistic answers. All your answers should be firm in the Bible's teaching. Even if it is offensive. Do not skate around the truths of the Bible. Do not shade the realities of the bible by blaming it on the context of the time. The bible is truth, period.
    Speak the truth from the bible about abominabler sins such as homosexuality, etc and do not water it down.
    You can offend people, that is fine. If it is truth from the bible, you MUST speak it. Both the Old and New testaments.
  `,
  safetySettings: safetySettings,
});

const createEventLog = (event, eventContent) => {
  logEvent(analytics, event, eventContent);
};

const startChatSession = async (history) => {
  const formattedHistory = history.map((msg) => ({
    role: msg.role,
    parts: [{ text: msg.content }],
  }));

  const session = model.startChat({
    history: formattedHistory,
    responseValidation: false,
    generationConfig: {
      maxOutputTokens: 8000,
    },
  });
  return session;
};

const GoogleSignInButton = ({ onClick }) => (
  <button
    id="google-sign-in-button"
    className="gsi-material-button"
    onClick={onClick}
  >
    <div className="gsi-material-button-state"></div>
    <div className="gsi-material-button-content-wrapper">
      <div className="gsi-material-button-icon">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{ display: "block" }}
        >
          <path
            fill="#EA4335"
            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
          ></path>
          <path
            fill="#4285F4"
            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
          ></path>
          <path
            fill="#FBBC05"
            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
          ></path>
          <path
            fill="#34A853"
            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
          ></path>
          <path fill="none" d="M0 0h48v48H0z"></path>
        </svg>
      </div>
      <span className="gsi-material-button-contents">Continue with Google</span>
      <span style={{ display: "none" }}>Continue with Google</span>
    </div>
  </button>
);

const sendMessage = async (userId, question) => {
  const response = await fetch("/flask_app/send_message", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId, question }),
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let result = "";
  let done = false;

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    result += decoder.decode(value);
  }

  return result;
};

const createPortalSession = async (stripeCustomerId, returnUrl) => {
  const createPortalSessionFn = httpsCallable(functions, "createPortalSession");
  const { data } = await createPortalSessionFn({
    stripeCustomerId,
    returnUrl,
  });
  return data;
};

const createCheckoutSession = async (userId, amount, returnUrl) => {
  const createCheckoutSessionFn = httpsCallable(
    functions,
    "createCheckoutSession"
  );
  const { data } = await createCheckoutSessionFn({
    userId,
    amount,
    returnUrl,
  });
  return data;
};

const checkoutSessionStatus = async (sessionId) => {
  const checkoutSessionStatusFn = httpsCallable(
    functions,
    "checkoutSessionStatus"
  );
  const { data } = await checkoutSessionStatusFn({
    sessionId,
  });
  return data;
};

const checkSubscriptionStatus = async (userId) => {
  const checkSubscriptionStatusFn = httpsCallable(
    functions,
    "checkSubscriptionStatus"
  );
  const { data } = await checkSubscriptionStatusFn({ userId });
  return data.isSubscribed;
};

const createSubscription = async ({
  paymentMethodId,
  amount,
  userId,
  email,
}) => {
  const createSubscriptionFn = httpsCallable(functions, "createSubscription");
  const { data } = await createSubscriptionFn({
    paymentMethodId,
    amount,
    userId,
    email,
  });
  return data;
};

const cancelSubscription = async (userId) => {
  const cancelSubscriptionFn = httpsCallable(functions, "cancelSubscription");
  const { data } = await cancelSubscriptionFn({ userId });
  return data;
};

export {
  GoogleSignInButton,
  createEventLog,
  firebaseApp,
  auth,
  model,
  functions,
  db,
  GoogleAuthProvider,
  getDoc,
  doc,
  setDoc,
  onSnapshot,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  sendMessage,
  onAuthStateChanged,
  startChatSession,
  createPortalSession,
  createCheckoutSession,
  checkoutSessionStatus,
  checkSubscriptionStatus,
  createSubscription,
  cancelSubscription,
};
