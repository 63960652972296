import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h1>Privacy Policy</h1>
            <p>
                This Privacy Policy ("Policy") applies to Evangara, and FIRELOCK, LLC ("Company") and 
                governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, 
                all references to the Company include evangara.com, Fortin Ventures, and firelock.io. The 
                Company's application is a chat application utilizing generative AI technology to provide an engaging and informative experience centered around discussions and content related to the Christian Bible and Faith.
                The application is designed to facilitate user exploration, understanding, and conversation about biblical topics through AI-generated interactions.
            </p>
            <h2>Collection of your Personal Information</h2>
            <p>
                In order to better provide you with products and services offered, the Company may collect 
                personally identifiable information, such as your: First and last name, Mailing address, Email address, Phone number.
            </p>
            <p>
                If you purchase the Company's products and services, we collect billing and credit card 
                information. This information is used to complete the purchase transaction.
            </p>
            <p>
                The Company may also collect anonymous demographic information, which is not unique to you, 
                such as your: Age, Gender, Race, Religion, Political affiliation, Household income.
            </p>
            <h2>Use of your Personal Information</h2>
            <p>
                The Company collects and uses your personal information to operate and deliver the services you have requested, to provide you with information, products, or services that you request from us, to provide you with notices about your account, to carry out the Company's obligations and enforce our rights arising from any contracts entered between you and us, and to notify you about changes to our services.
            </p>
            <h2>Sharing Information with Third Parties</h2>
            <p>
                The Company does not sell, rent, or lease its customer lists to third parties. The Company may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable 
                information (email, name, address, phone number) is transferred to the third party. The Company 
                may share data with trusted partners to help perform statistical analysis, send you email or postal 
                mail, provide customer support, or arrange for deliveries.
            </p>
            <h2>Opt-Out of Disclosure of Personal Information to Third Parties</h2>
            <p>
                You have the right under the California Consumer Privacy Act of 2018 (CCPA) and certain other 
                privacy and data protection laws, as applicable, to opt out of the disclosure of your personal 
                information. To opt out of the disclosure of your personal information, visit this web page, email: opt-out@firelock.io, or call: 678-296-0086.
            </p>
            <h2>Right to Deletion</h2>
            <p>
                Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will 
                delete your personal information from our records; and direct any service providers to delete your personal information from their records. Please note that we may not be able to comply with requests to delete your personal information if it is necessary to complete the transaction for which the personal information was collected, detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for that activity, among other reasons.
            </p>
            <h2>Children Under Thirteen</h2>
            <p>
                The Company does not knowingly collect personally identifiable information from children under 
                the age of 13. If you are under the age of 13, you must ask your parent or guardian for permission 
                to use this application.
            </p>
            <h2>Opt Out and Unsubscribe from Third-Party Communications</h2>
            <p>
                Users may opt out of receiving any or all communications from third-party partners of the Company by contacting us here: Email Address: privacy@firelock.io, Phone Number: 678-296-0086.
            </p>
            <h2>Email Communications</h2>
            <p>
                If you would like to stop receiving marketing or promotional communications via email from the Company, you may opt out of such communications by clicking on the unsubscribe button.
            </p>
            <h2>External Data Storage Sites</h2>
            <p>
                We may store your data on servers provided by third-party hosting vendors with whom we have 
                contracted.
            </p>
            <h2>Changes to This Statement</h2>
            <p>
                The Company reserves the right to change this Policy from time to time. For example, when there 
                are changes in our services, changes in our data protection practices, or changes in the law. When 
                changes to this Policy are significant, we will inform you. You may receive a notice by sending an 
                email to the primary email address specified in your account, by placing a prominent notice on our 
                FIRELOCK, LLC, and/or by updating any privacy information.
            </p>
            <h2>Contact Information</h2>
            <p>
                The Company welcomes your questions or comments regarding this Policy. If you believe that the 
                Company has not adhered to this Policy, please contact the Company at: FIRELOCK, LLC, 410 Pebblestone Circle SE, Marietta, Georgia 30067, Email Address: privacy@firelock.io.
            </p>
            <p>Effective as of July 05, 2024</p>
        </div>
    );
}

export default PrivacyPolicy;