import React from "react";

const Terms = () => (
  <div>
    <h2>Terms and Conditions</h2>
    <h3>Agreement between User and FIRELOCK, LLC</h3>
    <p>
      <strong>Effective Date:</strong> July 05, 2024
    </p>
    <p>
      Welcome to FIRELOCK, LLC's websites and services, including evangara.com
      ("Site") and our artificial intelligence services ("AI Service"). These
      websites and services are offered to you conditioned on your acceptance
      without modification of the terms, conditions, and notices contained
      herein (the "Terms"). Your use of our websites and services constitutes
      your agreement to all such Terms. Please read these terms carefully, and
      keep a copy of them for your reference.
    </p>
    <p>
      evangara.com is a generative artificial intelligence (AI) chat Site. The
      purpose of this website is to provide users with an engaging and
      informative chat experience centered around discussions and content
      related to the Bible. Utilizing advanced generative AI technology, the
      website facilitates exploration, understanding, and conversation about
      biblical topics. Through AI-driven interactions, users can delve deeper
      into biblical texts and concepts, fostering a greater appreciation and
      comprehension of the material.
    </p>

    <h3>Age Requirements</h3>
    <p>
      You must be at least 13 years or older to use our websites and services.
      If you are under 18 you must have your parent or legal guardian's
      permission to use them.
    </p>

    <h3>Use of Services and Content</h3>
    <p>
      You agree to use our websites and services only for lawful purposes and in
      compliance with all applicable laws and regulations. By using them, you
      agree not to engage in any activities that:
    </p>
    <ol type="a">
      <li>
        Violate any applicable federal, state, local, or international laws or
        regulations, including those pertaining to the export of data or
        software to and from the United States or other countries.
      </li>
      <li>
        Involve the transmission or solicitation of advertising or promotional
        material, including "junk mail," "chain letters," "spam," or any similar
        form of solicitation, unless you have obtained our prior written
        consent.
      </li>
      <li>
        Impersonate or attempt to impersonate the Company, a Company employee,
        another user, or any other person or entity, including the use of email
        addresses associated with any of the aforementioned parties.
      </li>
      <li>
        Engage in any conduct that limits or inhibits the use or enjoyment of
        our websites and services by others or may harm the Company, and its
        users, or expose them to legal liability.
      </li>
      <li>
        Interfere with or disrupt the functioning of our websites and services
        or violates the rights of others.
      </li>
      <li>
        Use the AI Service to develop machine learning models or any related
        technology.
      </li>
    </ol>

    <p>
      The insights produced and provided by the AI Service ("Output") are
      derived from the processed metadata on the Platform ("Input"). Both the
      Input and Output are collectively referred to as "Content." The
      responsibility for ensuring that the Content adheres to relevant laws and
      these Terms rests solely with you. The Content can be utilized for legal
      purposes, including commercial use and publication, at the customer's own
      discretion. Before publication, it is advisable to disclose that the
      Output was generated using artificial intelligence tools. Given the nature
      of machine learning, the Output may not be exclusive, and the Service
      might produce similar or identical results for other users.
    </p>

    <h3>Intellectual Property</h3>
    <p>
      All intellectual property rights related to our websites and services,
      including but not limited to software, content, and trademarks, are owned
      by, or licensed to the Company. Ownership and intellectual property rights
      belong to the Company. You may not copy, modify, or distribute our
      websites and services or any Outputs, or reproduce any part of them
      without our prior written consent.
    </p>

    <h3>Electronic Communications</h3>
    <p>
      Visiting our websites or sending emails to FIRELOCK, LLC constitutes
      electronic communications. You consent to receive electronic
      communications and you agree that all agreements, notices, disclosures and
      other communications that we provide to you electronically, via email and
      on the Site, satisfy any legal requirement that such communications be in
      writing.
    </p>

    <h3>Your Account</h3>
    <p>
      If you use this site, you are responsible for maintaining the
      confidentiality of your account and password and for restricting access to
      your computer, and you agree to accept responsibility for all activities
      that occur under your account or password. You may not assign or otherwise
      transfer your account to any other person or entity. You acknowledge that
      FIRELOCK, LLC is not responsible for third party access to your account
      that results from theft or misappropriation of your account. FIRELOCK, LLC
      and its associates reserve the right to refuse or cancel service,
      terminate accounts, or remove or edit content in our sole discretion.
    </p>

    <h3>Children Under Thirteen</h3>
    <p>
      FIRELOCK, LLC does not knowingly collect, either online or offline,
      personal information from persons under the age of thirteen. If you are
      under 18, you may use our websites and services only with permission of a
      parent or guardian.
    </p>

    <h3>Cancellation/Refund Policy</h3>
    <p>
      The subscription service is provided on a month-to-month basis. Refunds
      for subscription fees already paid are not available. Subscribers may
      cancel their subscription at any time prior to the renewal date.
    </p>
    <p>
      In the event of a mid-month cancellation, the subscriber will continue to
      have access to the service until the end of the current subscription
      period. Access to the service will remain uninterrupted for the remainder
      of the subscription term. Upon expiration of the current subscription
      period, the subscription will not be renewed, and access to the service
      will be terminated.
    </p>

    <h3>Links to Third Party Sites/Third Party Services</h3>
    <p>
      Our websites and services may contain links to other websites ("Linked
      Sites"). The Linked Sites are not under the control of FIRELOCK, LLC and
      FIRELOCK, LLC is not responsible for the contents of any Linked Site,
      including without limitation any link contained in a Linked Site, or any
      changes or updates to a Linked Site. FIRELOCK, LLC is providing these
      links to you only as a convenience, and the inclusion of any link does not
      imply endorsement by FIRELOCK, LLC of the site or any association with its
      operators.
    </p>
    <p>
      Certain services made available via our websites and services are
      delivered by third party sites and organizations. By using any product,
      service or functionality originating from the FIRELOCK, LLC domain, you
      hereby acknowledge and consent that FIRELOCK, LLC may share such
      information and data with any third party with whom FIRELOCK, LLC has a
      contractual relationship to provide the requested product, service or
      functionality on behalf of its users and customers.
    </p>

    <h3>No Unlawful or Prohibited Use/Intellectual Property</h3>
    <p>
      You are granted a non-exclusive, non-transferable, revocable license to
      access and use FIRELOCK, LLC's websites and services strictly in
      accordance with these terms of use. As a condition of your use, you
      warrant to FIRELOCK, LLC that you will not use them for any purpose that
      is unlawful or prohibited by these Terms. You may not use them in any
      manner which could damage, disable, overburden, or impair them or
      interfere with any other party's use and enjoyment of them. You may not
      obtain or attempt to obtain any materials or information through any means
      not intentionally made available or provided for through them.
    </p>

    <p>
      All content included as part of the Service, such as text, graphics,
      logos, images, as well as the compilation thereof, and any software used,
      is the property of FIRELOCK, LLC or its suppliers and protected by
      copyright and other laws that protect intellectual property and
      proprietary rights. You agree to observe and abide by all copyright and
      other proprietary notices, legends or other restrictions contained in any
      such content and will not make any changes thereto.
    </p>
    <p>
      You will not modify, publish, transmit, reverse engineer, participate in
      the transfer or sale, create derivative works, or in any way exploit any
      of the content, in whole or in part, found on FIRELOCK, LLC's websites and
      services. FIRELOCK, LLC content is not for resale. Your use does not
      entitle you to make any unauthorized use of any protected content, and in
      particular you will not delete or alter any proprietary rights or
      attribution notices in any content. You will use protected content solely
      for your personal use, and will make no other use of the content without
      the express written permission of FIRELOCK, LLC and the copyright owner.
      You agree that you do not acquire any ownership rights in any protected
      content. We do not grant you any licenses, express or implied, to the
      intellectual property of FIRELOCK, LLC or our licensors except as
      expressly authorized by these Terms.
    </p>

    <h3>International Users</h3>
    <p>
      The Service is controlled, operated and administered by FIRELOCK, LLC from
      our offices within the USA. If you access the Service from a location
      outside the USA, you are responsible for compliance with all local laws.
      You agree that you will not use the FIRELOCK, LLC Content accessed through
      our websites and services in any country or in any manner prohibited by
      any applicable laws, restrictions or regulations.
    </p>

    <h3>Indemnification</h3>
    <p>
      You agree to indemnify, defend and hold harmless FIRELOCK, LLC, its
      officers, directors, employees, agents and third parties, for any losses,
      costs, liabilities and expenses (including reasonable attorney's fees)
      relating to or arising out of your use of or inability to use our websites
      and services, any user postings made by you, your violation of any terms
      of this Agreement or your violation of any rights of a third party, or
      your violation of any applicable laws, rules or regulations. FIRELOCK, LLC
      reserves the right, at its own cost, to assume the exclusive defense and
      control of any matter otherwise subject to indemnification by you, in
      which event you will fully cooperate with FIRELOCK, LLC in asserting any
      available defenses.
    </p>

    <h3>Arbitration</h3>
    <p>
      In the event the parties are not able to resolve any dispute between them
      arising out of or concerning these Terms and Conditions, or any provisions
      hereof, whether in contract, tort, or otherwise at law or in equity for
      damages or any other relief, then such dispute shall be resolved only by
      final and binding arbitration pursuant to the Federal Arbitration Act,
      conducted by a single neutral arbitrator and administered by the American
      Arbitration Association, or a similar arbitration service selected by the
      parties, in a location mutually agreed upon by the parties. The
      arbitrator's award shall be final, and judgment may be entered upon it in
      any court having jurisdiction. In the event that any legal or equitable
      action, proceeding or arbitration arises out of or concerns these Terms
      and Conditions, the prevailing party shall be entitled to recover its
      costs and reasonable attorney's fees. The parties agree to arbitrate all
      disputes and claims in regards to these Terms and Conditions or any
      disputes arising as a result of these Terms and Conditions, whether
      directly or indirectly, including Tort claims that are a result of these
      Terms and Conditions. The parties agree that the Federal Arbitration Act
      governs the interpretation and enforcement of this provision. The entire
      dispute, including the scope and enforceability of this arbitration
      provision shall be determined by the Arbitrator. This arbitration
      provision shall survive the termination of these Terms and Conditions.
    </p>

    <h3>Class Action Waiver</h3>
    <p>
      Any arbitration under these Terms and Conditions will take place on an
      individual basis; class arbitrations and class/representative/collective
      actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS
      AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A
      PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
      REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY
      GENERAL ACTION AGAINST THE OTHER. Further, unless both you and FIRELOCK,
      LLC agree otherwise, the arbitrator may not consolidate more than one
      person's claims, and may not otherwise preside over any form of a
      representative or class proceeding.
    </p>

    <h3>Liability Disclaimer</h3>
    <p>
      THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE
      THROUGH FIRELOCK, LLC's WEBSITES AND SERVICES MAY INCLUDE INACCURACIES OR
      TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION
      HEREIN. FIRELOCK, LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR
      CHANGES IN THEM AT ANY TIME.
    </p>

    <p>
      FIRELOCK, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
      SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
      INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED
      ON FIRELOCK, LLC's WEBSITES AND SERVICES FOR ANY PURPOSE. TO THE MAXIMUM
      EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
      PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
      WARRANTY OR CONDITION OF ANY KIND. FIRELOCK, LLC AND/OR ITS SUPPLIERS
      HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
      INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING
      ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
    </p>

    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
      FIRELOCK, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
      PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
      WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR
      PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
      PERFORMANCE OF FIRELOCK, LLC's WEBSITES AND SERVICES, WITH THE DELAY OR
      INABILITY TO USE THEM OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
      PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
      RELATED GRAPHICS OBTAINED THROUGH THEM, OR OTHERWISE ARISING OUT OF THE
      USE OF THEM, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY
      OR OTHERWISE, EVEN IF FIRELOCK, LLC OR ANY OF ITS SUPPLIERS HAS BEEN
      ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS
      DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
      INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
    </p>
    <p>
      IF YOU ARE DISSATISFIED WITH ANY PORTION OF FIRELOCK, LLC's WEBSITES AND
      SERVICES, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE
      REMEDY IS TO DISCONTINUE USING THEM.
    </p>

    <h3>Termination/Access Restriction</h3>
    <p>
      FIRELOCK, LLC reserves the right, in its sole discretion, to terminate
      your access to its websites and services or any portion thereof at any
      time, without notice. To the maximum extent permitted by law, this
      agreement is governed by the laws of the State of Georgia and you hereby
      consent to the exclusive jurisdiction and venue of courts in Georgia in
      all disputes arising out of or relating to the use of the Site. Use of the
      Site is unauthorized in any jurisdiction that does not give effect to all
      provisions of these Terms, including, without limitation, this section.
    </p>

    <p>
      You agree that no joint venture, partnership, employment, or agency
      relationship exists between you and FIRELOCK, LLC as a result of this
      agreement or use of FIRELOCK, LLC's websites and services. FIRELOCK, LLC's
      performance of this agreement is subject to existing laws and legal
      process, and nothing contained in this agreement is in derogation of
      FIRELOCK, LLC's right to comply with governmental, court and law
      enforcement requests or requirements relating to your use of FIRELOCK,
      LLC's websites and services or information provided to or gathered by
      FIRELOCK, LLC with respect to such use. If any part of this agreement is
      determined to be invalid or unenforceable pursuant to applicable law
      including, but not limited to, the warranty disclaimers and liability
      limitations set forth above, then the invalid or unenforceable provision
      will be deemed superseded by a valid, enforceable provision that most
      closely matches the intent of the original provision and the remainder of
      the agreement shall continue in effect.
    </p>

    <p>
      Unless otherwise specified herein, this agreement constitutes the entire
      agreement between the user and FIRELOCK, LLC with respect to FIRELOCK,
      LLC's websites and services and it supersedes all prior or contemporaneous
      communications and proposals, whether electronic, oral or written, between
      the user and FIRELOCK, LLC with respect to FIRELOCK, LLC's websites and
      services. A printed version of this agreement and of any notice given in
      electronic form shall be admissible in judicial or administrative
      proceedings based upon or relating to this agreement to the same extent
      and subject to the same conditions as other business documents and records
      originally generated and maintained in printed form. It is the express
      wish to the parties that this agreement and all related documents be
      written in English.
    </p>

    <h3>Changes to Terms</h3>
    <p>
      FIRELOCK, LLC reserves the right, in its sole discretion, to change the
      Terms under which its websites and services are offered. The most current
      version of the Terms will supersede all previous versions. FIRELOCK, LLC
      encourages you to periodically review the Terms to stay informed of our
      updates.
    </p>

    <h3>Privacy</h3>
    <p>
      The Company believes strongly in the protection of your privacy. We do not
      collect your personal information unless you voluntarily provide it to us.
      Please review our Privacy Policy, which also governs our websites and
      services and informs users of our data collection practices.
    </p>

    <h3>Accuracy and Limitations of Liability</h3>
    <p>
      Our websites and services are provided on an "as is" basis, and we make no
      warranties or representations regarding their accuracy, reliability, or
      suitability for any purpose. The Company shall not be liable for any
      direct, indirect, incidental, consequential, or punitive damages arising
      out of or in connection with the use of our websites and services. The
      information provided by them is intended for general informational
      purposes only. Any reliance you place on such information is done so
      strictly at your own risk. The Company assumes no liability or
      responsibility for any reliance placed on such materials by you, other
      users, or any individuals who may be informed of its contents.
    </p>

    <h3>Disclaimer</h3>
    <p>
      The AI Service uses experimental technology, and you should use discretion
      before relying on, publishing, or using content generated by the Service.
      The information provided by the AI Service is intended for general
      informational purposes only. The Company makes no guarantees regarding the
      accuracy, completeness, or usefulness of this information. Any reliance
      you place on such information is done so strictly at your own risk. The
      Company assumes no liability or responsibility for any reliance placed on
      such materials by you, other users of the Service, or any individuals who
      may be informed of its contents.
    </p>

    <h3>Contact Us</h3>
    <p>
      FIRELOCK, LLC welcomes your questions or comments regarding the Terms:
    </p>
    <address>
      FIRELOCK, LLC
      <br />
      410 Pebblestone Cir SE
      <br />
      Marietta, Georgia 30067
    </address>
    <p>
      <strong>Email Address:</strong>
      <br />
      General: terms@firelock.io
      <br />
      AI Service: aiterms@firelock.io
      <br />
    </p>
  </div>
);

export default Terms;
