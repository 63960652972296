import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactMarkdown from "react-markdown";
import {
  Box,
  TextField,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  db,
  setDoc,
  doc,
  onSnapshot,
  startChatSession,
} from "../utils/firebase";
import { Timestamp } from "firebase/firestore";

const Chat = ({
  user,
  loading,
  setLoading,
  isSubscribed,
  setIsSubscribed,
  isActive,
  setIsActive,
  paymentDialogOpen,
  setPaymentDialogOpen,
}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [streamedResponse, setStreamedResponse] = useState("");
  const endOfMessagesRef = useRef(null);

  const saveChatHistory = useCallback(async (userId, chatHistory) => {
    try {
      await setDoc(doc(db, "chatHistories", userId), { history: chatHistory });
    } catch (error) {
      console.error("Error saving chat history:", error);
    }
  }, []);

  useEffect(() => {
    if (user && user.uid) {
      const docRef = doc(db, "chatHistories", user.uid);
      const unsubscribe = onSnapshot(
        docRef,
        (doc) => {
          if (doc.exists()) {
            setMessages(doc.data()?.history);
          } else {
          }
        },
        (error) => {
          console.error("Error loading chat history:", error);
        }
      );

      return () => unsubscribe(); // Cleanup the subscription on unmount
    } else {
      setMessages([]);
    }
  }, [user, setLoading]);

  useEffect(() => {
    if (user && user.uid) {
      if (isActive) {
        setPaymentDialogOpen(false);
      } else if (!isActive && messages.length > 10) {
        setPaymentDialogOpen(true);
      } else {
        setPaymentDialogOpen(false);
      }
    }
  }, [isActive, messages, user]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, paymentDialogOpen, streamedResponse]);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = {
      role: "user",
      content: input,
      timestamp: Timestamp.now(),
    };
    const existingHistory = messages;
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setStreamedResponse("");

    try {
      const chatSession = await startChatSession(existingHistory);

      const result = await chatSession.sendMessageStream(input);
      let responseText = "";
      for await (const chunk of result.stream) {
        responseText += chunk.text();
        setStreamedResponse(responseText);
      }

      const modelMessage = {
        role: "model",
        content: responseText,
        timestamp: Timestamp.now(),
      };
      setStreamedResponse("");

      setMessages((prevMessages) => [...prevMessages, modelMessage]);
      saveChatHistory(user.uid, [...messages, userMessage, modelMessage]);

      if (!isActive && messages.length >= 10) {
        setPaymentDialogOpen(true);
      } else {
        setPaymentDialogOpen(false);
      }
    } catch (error) {
      console.error("Error handling chat");
    }
  };
  return (
    <Box
      className="Chat-container"
      sx={{
        display: "flex",
        maxHeight: "98%",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 20px 0px 20px",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Paper
        className="Chat-content"
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          maxWidth: "800px",
          padding: "0px 20px",
          paddingTop: "20px",
          marginBottom: "5px",
          backgroundColor: "#4f4f4f",
          boxShadow:
            "0px 3px 3px -5px rgba(0,0,0,1),0px 5px 4px 0px rgba(0,0,0,0),0px 0px 20px 0px rgba(0,0,0,0.3) inset",
        }}
      >
        <>
          {messages.length === 0 && (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Welcome to Evangara!
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#D3D3D3",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                I'm here to help you explore and understand the Christian faith.
                You can ask me anything about the Bible, Christianity, or share
                your thoughts and questions. Just type your message below to get
                started. Let's dive deep into the love and teachings of Jesus
                together!
              </Typography>
            </>
          )}
          {user && (
            <>
              {messages.map((message, index) => (
                <Box
                  key={index}
                  className={`message ${message.role}`}
                  sx={{ marginBottom: "10px" }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: message.role === "model" ? "#6200ea" : "#000000",
                    }}
                  >
                    <ReactMarkdown>{message.content}</ReactMarkdown>
                  </Typography>
                </Box>
              ))}
              {streamedResponse && (
                <Box className="message model">
                  <Typography variant="body1" sx={{ color: "#6200ea" }}>
                    <ReactMarkdown>{streamedResponse}</ReactMarkdown>
                  </Typography>
                </Box>
              )}
              <div ref={endOfMessagesRef} />
            </>
          )}
        </>
      </Paper>
      {user && (
        <>
          <form
            onSubmit={
              isActive || messages.length <= 10 ? handleSubmit : () => {}
            }
            className="Chat-form"
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <TextField
              fullWidth
              autoComplete="off"
              variant="filled"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Message Evangara"
              sx={{
                "& .MuiInputBase-root.MuiFilledInput-root:after": {
                  borderBottomColor: "#b469ff",
                },
              }}
            />
            <IconButton
              type="submit"
              color="primary"
              sx={{ marginLeft: "10px" }}
            >
              <SendIcon />
            </IconButton>
          </form>
        </>
      )}
    </Box>
  );
};

export default Chat;
