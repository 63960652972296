import React, { useState, useEffect, useRef, useCallback } from "react";
import "./App.css";
import Chat from "./components/Chat";
import LoginPage from "./components/LoginPage";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Logo from "./logo-purple.svg";
import {
  signOut,
  onAuthStateChanged,
  auth,
  checkSubscriptionStatus,
  db,
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  createPortalSession,
} from "./utils/firebase";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import PaymentDialog from "./components/PaymentDialog";
import WelcomeDialog from "./components/WelcomeDialog";
import useWatchURL from "./utils/useWatchUrl";

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [managingSubscription, setManageSubscription] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(false);

  const activateUser = () => {
    setManageSubscription(false);
    setIsActive(true);
    setIsSubscribed(true);
    setPaymentDialogOpen(false);
  };

  useWatchURL(activateUser);

  const prevUserRef = useRef(user);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const createUserDocument = useCallback(async (user) => {
    console.log(`Creatng/Updating User Document: ${user.email}`);
    try {
      await setDoc(
        doc(db, "users", user.uid),
        {
          email: user.email,
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error creating user document:", error);
    }
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      if (user && user.uid && prevUserRef.current?.uid !== user?.uid) {
        setLoading(true);
        await createUserDocument(user);
      }
      setLoading(false);
      prevUserRef.current = user;
    };
    checkUser();
  }, [user, setLoading, createUserDocument]);

  useEffect(() => {
    if (user && user.uid) {
      checkSubscriptionStatus(user.uid);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.uid) {
      const docRef = doc(db, "users", user.uid);
      const unsubscribe = onSnapshot(
        docRef,
        (doc) => {
          if (doc.exists()) {
            setIsSubscribed(doc.data().isSubscribed);
            const rDate = doc.data().renewalDate;
            setRenewalDate(rDate ? rDate.toDate() : null);
          }
        },
        (error) => {
          console.error("Error loading chat history:", error);
        }
      );

      return () => unsubscribe(); // Cleanup the subscription on unmount
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (newUser) => {
      setLoading(true);
      if (newUser) {
        const userDataSnapshot = await getDoc(doc(db, "users", newUser.uid));
        let userData = {};
        if (userDataSnapshot.exists()) {
          userData = userDataSnapshot.data();
        }
        setUser((prevUser) => {
          if (prevUser?.uid !== newUser.uid) {
            return { ...newUser, ...userData };
          }
          return prevUser;
        });
        setWelcomeDialogOpen(true);
      }
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (renewalDate && renewalDate >= new Date()) {
      setIsActive(true);
    } else if (renewalDate) {
      setIsActive(false);
    }
  }, [isSubscribed, renewalDate]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleManageSubscription = async () => {
    setDrawerOpen(true);
    setManageSubscription(true);
    setLoading(true);
    try {
      if (user) {
        const { url } = await createPortalSession(
          user?.stripeCustomerId,
          window.location.origin
        );
        window.location = url;
      }
    } catch (error) {
      console.error("Error managing subscription:", error);
      alert("Failed to Manage Subscription. Please try again.");
    } finally {
      setDrawerOpen(false);
      setManageSubscription(false);
      setLoading(false);
    }
  };

  const drawerList = () =>
    user && (
      <Box
        sx={{
          width: 250,
          backgroundColor: "#2C2C2C",
          color: "#ffffff",
          height: "100%",
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button onClick={handleSignOut} sx={{ color: "#ffffff" }}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleManageSubscription()}
            sx={{ color: "#ffffff" }}
          >
            <ListItemIcon>
              <AccountBalanceWalletIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            <ListItemText primary="Manage Subscription" />
            {managingSubscription && (
              <CircularProgress size={24} sx={{ color: "#ffffff", ml: 2 }} />
            )}
          </ListItem>
          {!isSubscribed && renewalDate && (
            <ListItem sx={{ color: "#ffffff" }}>
              <ListItemText
                primary={`Your Subscription Expires on ${
                  renewalDate ? renewalDate.toLocaleDateString() : renewalDate
                }`}
              />
            </ListItem>
          )}
        </List>
      </Box>
    );

  return (
    <>
      <Box
        className="App"
        sx={{ backgroundColor: "#f4f6f8", height: "100vh", overflow: "none" }}
      >
        <AppBar
          position="static"
          className="App-header"
          sx={{ backgroundColor: "#2C2C2C" }}
        >
          <Toolbar>
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <img
                src={Logo}
                alt="Logo"
                style={{ height: "40px", width: "auto", marginRight: "auto" }}
              />
            </div>
            {user && (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        {user && (
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {drawerList()}
          </Drawer>
        )}
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box className="Page-container">
          {user ? (
            <Chat
              user={user}
              loading={loading}
              setLoading={setLoading}
              isSubscribed={isSubscribed}
              setIsSubscribed={setIsSubscribed}
              isActive={isActive}
              setIsActive={setIsActive}
              paymentDialogOpen={paymentDialogOpen}
              setPaymentDialogOpen={setPaymentDialogOpen}
            />
          ) : (
            <LoginPage onLoginSuccess={setUser} />
          )}
        </Box>

        <Box
          component="footer"
          sx={{
            p: 2,
            textAlign: "center",
            fontSize: "10px",
            padding: "0px",
            paddingBottom: "15px",
            backgroundColor: "#4f4f4f",
            color: "#ffffff",
          }}
        >
          <span style={{ padding: "0px 5px" }}>
            By messaging Evangara, you agree to our{" "}
            <Link href="#" color="inherit" onClick={() => setTermsOpen(true)}>
              Terms
            </Link>{" "}
            and that you have read our{" "}
            <Link
              href="#"
              color="inherit"
              onClick={() => setPrivacyPolicyOpen(true)}
            >
              Privacy Policy
            </Link>
            .
          </span>
        </Box>
        <Dialog
          open={privacyPolicyOpen}
          onClose={() => setPrivacyPolicyOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <PrivacyPolicy />
          </DialogContent>
        </Dialog>
        <Dialog
          open={termsOpen}
          onClose={() => setTermsOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <Terms />
          </DialogContent>
        </Dialog>
        {/* <WelcomeDialog
          open={welcomeDialogOpen}
          handleClose={() => setWelcomeDialogOpen(false)}
        /> */}
      </Box>
      {paymentDialogOpen && (
        <PaymentDialog
          user={user}
          open={paymentDialogOpen}
          setOpen={setPaymentDialogOpen}
          onClose={(event, reason) => {
            if (reason && reason === "backdropClick") return;
            setPaymentDialogOpen(false);
          }}
          setIsSubscribed={setIsSubscribed}
          setIsActive={setIsActive}
        />
      )}
    </>
  );
}

export default App;
