import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import {
  GoogleSignInButton,
  GoogleAuthProvider,
  signInWithPopup,
  auth,
} from "../utils/firebase";

const LoginPage = ({ onLoginSuccess }) => {
  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const { user } = result;
      onLoginSuccess(user);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <Paper
      elevation={6}
      sx={{
        padding: "20px",
        maxWidth: "600px",
        width: "85%",
        textAlign: "center",
        backgroundColor: "#2C2C2C",
        color: "#FFFFFF",
        borderRadius: "12px",
      }}
    >
      <Typography
        variant="h3"
        sx={{ marginBottom: "20px", fontWeight: "bold" }}
      >
        Welcome to Evangara!
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginBottom: "30px", fontSize: "1.2rem" }}
      >
        Discover the love and teachings of Jesus Christ. Our conversational AI
        is here to help you explore and understand the Christian faith. Sign in
        to start your journey.
      </Typography>
      <GoogleSignInButton onClick={handleLogin} />
    </Paper>
  );
};

export default LoginPage;
